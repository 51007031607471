import * as React from "react";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import WarningMessage from "../WarningMessage";
import "./style.css";

const FixedTags = (props) => {
  const [value, setValue] = React.useState([]);
  const [isError, setIsError] = React.useState(false);

  React.useEffect(() => {
    setValue([]);
  }, [props?.refresh]);

  return (
    <div style={{ padding: "10px" }}>
      <Autocomplete
        multiple
        id="fixed-tags-demo"
        size="small"
        value={value}
        onChange={(event, newValue) => {
          if (newValue?.length <= 5) {
            setIsError(false);
            setValue([...newValue]);
            props.selectedData(newValue?.map((item) => item?.data));
          } else {
            setIsError(true);
          }
        }}
        options={props?.data}
        getOptionLabel={(option) => option.data}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => {
            const { key, ...tagProps } = getTagProps({ index });
            return <Chip key={key} label={option.data} {...tagProps} />;
          })
        }
        style={{ width: "100%" }}
        renderInput={(params) => <TextField {...params} label={props?.label} placeholder="Type here to search" />}
      />
      {isError && <WarningMessage type="error" text="Only 5 filter is allowed at a time." />}
    </div>
  );
};

export default FixedTags;
