import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Status from "../Status/Status";
import imgPath from "../../assets/images/dataNotFound.png";
import { NO_DATA_FOUND } from "../../Constant/StaticData";
import { FormControl, Input, InputLabel, List, ListItem, ListItemButton, ListItemText, TextField } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#4a4a4a",
    color: theme.palette.common.white,
    fontSize: 12,
    fontWeight: 600,
    // whiteSpace: "nowrap",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    // whiteSpace: "nowrap",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  //   "&:nth-of-type(odd)": {
  //     backgroundColor: theme.palette.action.hover,
  //   },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const SecondLayerList = (props) => {
  return (
    <div>
      <>
        <TextField id="outlined-required" size="small" style={{ width: "100%" }} />
        <TableContainer component={Paper} style={{ height: "300px" }}>
          <Table
            // sx={{ minWidth: 700 }}
            size="small"
            aria-label="customized table"
            padding="6px"
            style={{ fontSize: "12px!important" }}
          >
            <TableBody>
              {props?.data?.map((item) => (
                <StyledTableRow key={item?.data} style={{ cursor: "pointer" }}>
                  <StyledTableCell align="left">{item?.data}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* <List dense sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
          {props?.data?.map((item) => (
            <ListItem>
              <ListItemButton>
                <ListItemText primary={item?.data} style={{ fontSize: "12px!important" }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List> */}
      </>
    </div>
  );
};

export default SecondLayerList;
