import * as React from "react";
import "./style/style.scss";
import { BrowserRouter as Router, Route, Routes, HashRouter } from "react-router-dom";

import { AboutUS, TermsConditions, Supplier, Buyer, Profile, PrivacyPolicy, ImportExport, Home, SomthingWentWrong, ContactUS, ValueWiseAnalysis, FullDataTable, ShipmentWiseAnalysis } from "./Pages";
import Reset from "./Pages/Reset";
import USATradeAnalysis from "./Pages/USA/USATradeAnalysis/USATradeAnalysis";

const App = () => {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUS />} />
        <Route path="/trade-analysis" element={<ImportExport />} />
        <Route path="/usa-trade-analysis" element={<USATradeAnalysis />} />

        <Route path="/trade-analysis-table/*" element={<FullDataTable />} />
        <Route path="/value-wise-analysis" element={<ValueWiseAnalysis />} />
        <Route path="/shipment-wise-analysis" element={<ShipmentWiseAnalysis />} />

        <Route path="/buyer" element={<Buyer />} />
        <Route path="/supplier" element={<Supplier />} />
        <Route path="/contact-us" element={<ContactUS />} />
        <Route path="/error" element={<SomthingWentWrong />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/reset/*" element={<Reset />} />
      </Routes>
    </HashRouter>
  );
};

export default App;
